const cities = [
    'balashikha',
    'vidnoye',
    'dmitrov',
    'dolgoprudny',
    'domodedovo',
    'zelenograd',
    'ivanteyevka',
    'kolomna',
    'kommunarka',
    'korolev',
    'kotelniki',
    'krasnogorsk',
    'lobnya',
    'lyubertsy',
    'moscow',
    'mytishchi',
    'nakhabino',
    'noginsk',
    'odintsovo',
    'podolsk',
    'pushkino',
    'reyutov',
    'tula',
    'fryazino',
    'khimki',
    'shchyolkovo',
    'elektrostal',
    'yaroslavl'
];

const cityInfo = [
    { name: 'Москва', slug: 'moscow', seoInCity: 'в Москве' },
    { name: 'Балашиха', slug: 'balashikha', seoInCity: 'в Балашихе' },
    { name: 'Видное', slug: 'vidnoye', seoInCity: 'в Видном' },
    { name: 'Дмитров', slug: 'dmitrov', seoInCity: 'в Дмитрове' },
    { name: 'Долгопрудный', slug: 'dolgoprudny', seoInCity: 'в Долгопрудном' },
    { name: 'Домодедово', slug: 'domodedovo', seoInCity: 'в Домодедово' },
    { name: 'Зеленоград', slug: 'zelenograd', seoInCity: 'в Зеленограде' },
    { name: 'Ивантеевка', slug: 'ivanteyevka', seoInCity: 'в Ивантеевке' },
    { name: 'Коломна', slug: 'kolomna', seoInCity: 'в Коломне' },
    { name: 'Коммунарка', slug: 'kommunarka', seoInCity: 'в Коммунарке' },
    { name: 'Королёв', slug: 'korolev', seoInCity: 'в Королёве' },
    { name: 'Котельники', slug: 'kotelniki', seoInCity: 'в Котельниках' },
    { name: 'Красногорск', slug: 'krasnogorsk', seoInCity: 'в Красногорске' },
    { name: 'Лобня', slug: 'lobnya', seoInCity: 'в Лобне' },
    { name: 'Люберцы', slug: 'lyubertsy', seoInCity: 'в Люберцах' },
    { name: 'Мытищи', slug: 'mytishchi', seoInCity: 'в Мытищах' },
    { name: 'Нахабино', slug: 'nakhabino', seoInCity: 'в Нахабино' },
    { name: 'Ногинск', slug: 'noginsk', seoInCity: 'в Ногинске' },
    { name: 'Одинцово', slug: 'odintsovo', seoInCity: 'в Одинцово' },
    { name: 'Подольск', slug: 'podolsk', seoInCity: 'в Подольске' },
    { name: 'Пушкино', slug: 'pushkino', seoInCity: 'в Пушкино' },
    { name: 'Реутов', slug: 'reyutov', seoInCity: 'в Реутове' },
    { name: 'Тула', slug: 'tula', seoInCity: 'в Туле' },
    { name: 'Фрязино', slug: 'fryazino', seoInCity: 'в Фрязино' },
    { name: 'Химки', slug: 'khimki', seoInCity: 'в Химках' },
    { name: 'Щёлково', slug: 'shchyolkovo', seoInCity: 'в Щёлково' },
    { name: 'Электросталь', slug: 'elektrostal', seoInCity: 'в Электростали' },
    { name: 'Ярославль', slug: 'yaroslavl', seoInCity: 'в Ярославле' }
];
// Создаем реактивную переменную для текущего города
const currentCity = ref(cityInfo[0]);

// Функция для поиска slug по названию города
export const useCities = () => {
    // Функция для поиска информации о городе по slug
    const getCityBySlug = (slug) => {
        const city = cityInfo.find(item => item.slug === slug);
        return city ? city : null; // Возвращаем объект города или null, если slug не найден
    };

    const getCityByName = (name) => {
        const city = cityInfo.find(item => item.name === name);
        return city ? city : null; // Возвращаем объект города или null, если slug не найден
    };

    // Функция для установки текущего города
    const setCurrentCity = (slug) => {
        const city = cityInfo.find(item => item.slug === slug);
        if (city) {
            currentCity.value = city; // Устанавливаем объект текущего города
        }
    };

    // Функция для получения текущего города
    const getCurrentCity = () => {
        return currentCity.value; // Возвращаем объект текущего города
    };

    return {
        cities,
        getCityByName,
        getCityBySlug,
        setCurrentCity,
        getCurrentCity,
        cityInfo
    };
};